import React from 'react'

import BlankLayout from '../components/layouts/blank_layout'
import PageHeading from '../components/common/page_heading'
import P from '../components/common/paragraph'
import H from '../components/common/header'
import PageLink from '../components/common/page_link'
import MetaHelmet from '../components/common/meta_helmet'
import { PALETTE_BASE_LIGHT, PALETTE_MUTED } from '../variables'

import Image from '../components/common/image'

import COFFEE_IMAGE_SRC from '../images/coffee.jpg'

export default () => (
  <BlankLayout>
    <MetaHelmet
      image={COFFEE_IMAGE_SRC}
      imageAlt="A cup of black coffee viewed from above"
      titlePrefixes={['Support Me']}
    />

    <Image
      src={COFFEE_IMAGE_SRC}
      alt="A cup of black coffee viewed from above"
    />

    <PageHeading text="Support Me" />

    <P>
      No pressure, but if you like the things I make on this site and want to
      see more, it'd be awesome if you'd consider supporting me.
    </P>

    <H h={2} heavy>
      Ko-fi
    </H>
    <PageLink
      external
      title="Buy me a wee coffee"
      link="https://ko-fi.com/unwttng"
      description="A couple of coins buys me the best day ever. Encourage more of... whatever it is I do."
      background="#F96854"
      foreground="white"
    />

    <P>
      Ko-fi lets you donate enough for a cup of coffee. More, if you like. It's
      quick, and it's a really easy one-off way to show me your support.
    </P>

    <H h={2} heavy>
      Social
    </H>
    <P>
      If you don't have any spare change lying around or aren't able to pledge a
      regular amount, you can support me for free.
    </P>

    <PageLink
      external
      title="Junie @unwttng on Twitter"
      link="https://twitter.com/unwttng"
      description={`Start here`}
      background="#4099FF"
      foreground="white"
    />

    <P>
      All you have to do is follow, like or retweet me on the Twitters, share my
      articles on your preferred networks, whatever. Any amount of this social
      spreading is valuable and hugely appreciated.
    </P>

    <PageLink
      pageID="a2bdb8e8-672d-43b0-b3f4-4083cd815b37"
      title="Home"
      description="What else is going on?"
      background={PALETTE_MUTED}
      foreground={PALETTE_BASE_LIGHT}
    />
  </BlankLayout>
)
